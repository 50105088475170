import React from 'react';
import { InputLabel, NoData, Poppins, Spacer } from 'src/common';
import styled from 'styled-components';
import { Header } from './comps';
import { useFormikContext } from 'formik';
import { formatDate } from 'src/utils/misc';
import { useStateSelector } from 'src/redux';
import { WizardIntent } from 'src/redux/reducers/app/types';
import { SlideId, WizardFormValues } from 'src/api/types/wizard';

const Div = styled.div`
  .ad-scenarios {
    display: grid;
    grid-gap: 12px;
    &__item {
      display: flex;
      justify-content: space-between;
      grid-gap: 6px;
    }
  }
`;

interface AssessmentDetailsProps {
  onChangeSlide: (id: SlideId) => void;
}

export const AssessmentDetails: React.FC<AssessmentDetailsProps> = ({ onChangeSlide }) => {
  const wizardWorkspace = useStateSelector(({ app }) => app.wizardWorkspace);
  const { values } = useFormikContext<WizardFormValues>();

  const isScenarioType = wizardWorkspace?.intent.type === WizardIntent.scenario;

  const scenariosData = [
    {
      label: 'Do you have a significant volume of business customers (B2B)?',
      value: values.scenarios.B2B,
    },
    {
      label: 'Do you have a significant volume of individual consumers (B2C)?',
      value: values.scenarios.B2C,
    },
    {
      label:
        'Does your business rely on a publicly accessible app, platform or ecommerce operation to generate revenue?',
      value: values.scenarios.publiclyAccessibleInternetBusiness,
    },
    {
      label: 'Do you process a large volume of, or high value of payments?',
      value: values.scenarios.paymentVolumeOrValue,
    },
    {
      label: 'Do you need functioning IT equipment as a primary way of generating revenue?',
      value: values.scenarios.significantITContributionToRevenue,
    },
    {
      label: 'Do you store large amounts of company data in the cloud?',
      value: values.scenarios.significantDataInCloud,
    },
    {
      label: 'Do you rely on your own bespoke software to generate revenue?',
      value: values.scenarios.softwareBusiness,
    },
    {
      label: 'Do you operate critical infrastructure, or part of the defence supply chain?',
      value: values.scenarios.cniOrDefenseInvolvement,
    },
    {
      label: 'Do you spend more than 5% of revenue on research & development?',
      value: values.scenarios.significantRandD,
    },
    {
      label: 'Are you a regulated business?',
      value: values.scenarios.regulated,
    },
    {
      label: 'Do you have a significant physical or manufacturing element to your business?',
      value: values.scenarios.operationalTechnology,
    },
    {
      label: 'Does your business heavily rely on any third party organisations or suppliers?',
      value: values.scenarios.supplyChainReliant,
    },
  ];

  return (
    <Div>
      {!isScenarioType && (
        <>
          <Header
            title="ASSESSMENT DETAILS"
            gradient
            onEdit={
              wizardWorkspace?.intent.type === WizardIntent.scenario
                ? undefined
                : () => onChangeSlide(SlideId.assessmentDetails)
            }
          />
          <InputLabel>ASSESSMENT NAME</InputLabel>
          {values.assessment.name ? (
            <Poppins px={14} weight={500}>
              {values.assessment.name}
            </Poppins>
          ) : (
            <>
              <Poppins px={14} color="error">
                Required
              </Poppins>
            </>
          )}
          <Spacer $px={20} />
          <InputLabel>ASSESSMENT DESCRIPTION</InputLabel>
          {values.assessment.description ? (
            <Poppins px={14} weight={500}>
              {values.assessment.description}
            </Poppins>
          ) : (
            <>
              <Poppins px={14} color="error">
                Required
              </Poppins>
            </>
          )}
          <Spacer $px={20} />
          <InputLabel>START DATE</InputLabel>
          {values.assessment.started_date ? (
            <Poppins px={14} weight={500}>
              {formatDate(values.assessment.started_date)}
            </Poppins>
          ) : (
            <NoData />
          )}
          <Spacer $px={20} />
          <InputLabel>REVIEW DATE</InputLabel>
          {values.assessment.review_date ? (
            <Poppins px={14} weight={500}>
              {formatDate(values.assessment.review_date)}
            </Poppins>
          ) : (
            <>
              <Poppins px={14} color="error">
                Required
              </Poppins>
            </>
          )}
          <Spacer $px={40} />
        </>
      )}
      <Header title="SCENARIOS" onEdit={() => onChangeSlide(SlideId.scenarios)} gradient={isScenarioType} />
      <div className="ad-scenarios">
        {scenariosData.map((el) => (
          <div key={el.label} className="ad-scenarios__item">
            <Poppins px={14} color="davysGrey">
              {el.label}
            </Poppins>
            <Poppins px={14}>{el.value ? 'Yes' : 'No'}</Poppins>
          </div>
        ))}
      </div>
    </Div>
  );
};
