import React from 'react';
import { Poppins } from 'src/common';
import { useFiltersState } from 'src/pages/DashboardPage/Filters/state';
import styled from 'styled-components';
import _ from 'lodash';

const Div = styled.div`
  position: relative;
  display: grid;
  flex-direction: column;
  grid-gap: 6px;
  .ip-title {
    margin: 4px 0;
  }
  .ip-divider {
    margin-top: 8px;
  }
`;

interface IPContentsUsersProps {}

export const IPContentsUsers: React.FC<IPContentsUsersProps> = () => {
  const { filters } = useFiltersState();

  const users = Object.keys(filters?.owners || {})
    .filter((key) => filters.owners[key].enabled)
    .sort((a, b) => {
      if (a === 'No Owner') return -1;
      if (b === 'No Owner') return 1;
      return a.localeCompare(b, undefined, { sensitivity: 'base' });
    })
    .map((el) => (el === 'No Owner' ? '[Blank]' : el));

  return (
    <Div>
      {!!users.length && (
        <>
          <Poppins px={14} weight={500} color="cflowerBlue" className="ip-title">
            OWNERS
          </Poppins>
          {users.map((el) => (
            <Poppins
              key={el}
              px={14}
              weight={500}
              color="davysGrey"
              nowrap
              style={{ fontStyle: el === '[Blank]' ? 'italic' : undefined }}
            >
              {el}
            </Poppins>
          ))}
        </>
      )}
    </Div>
  );
};
