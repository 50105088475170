// @ts-nocheck
import { Image, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { PdfScenario, riskConsequencesNames, riskEventsNames, riskSourcesNames } from 'src/api/types';
import { FooterStartComponent, Header } from './Header';
import { pdfStyles } from '../pdfStyles';
import { Footer } from './Footer';
import { formatDate } from '../util';
import { isUndefined, numeralFormat } from 'src/utils/misc';
import { LegendExceedance } from './legends';
import { ControlsTable } from './ControlsTable';
import { IncidentsTable } from './IncidentsTable';
import { NoData } from './NoData';
import { EmptyChart } from './EmptyChart';
import { TitleLink } from './TitleLink';

interface ScenarioPageProps {
  scenario: PdfScenario;
  onRender: (pageNumber: number) => void;
  endComponent?: React.ReactNode;
  assessmentData: {
    reviewDate: string;
    id: string;
  };
  footerTitle?: string;
}

export const ScenarioPage: React.FC<ScenarioPageProps> = ({
  scenario,
  onRender,
  endComponent,
  assessmentData,
  footerTitle = '',
}) => {
  const isEmptyChart = scenario.chart?.every((el) => el.y === 1 && el.x === 0);

  console.log('scenario', scenario);

  return (
    <>
      <Page size={'A4'} style={pdfStyles.page}>
        <Header endComponent={endComponent} />

        <View render={(data) => onRender(data.pageNumber)} />
        <View style={pdfStyles.section}>
          <TitleLink
            title={scenario.name}
            intLinkId={scenario.name.toLowerCase()}
            extLink={`risk-assessments/${assessmentData.id}/scenario/${scenario.id}`}
          />

          <View style={pdfStyles.spacer8} />
          <View style={pdfStyles.spacer4} />

          <>
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>REVIEW DATE</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{formatDate(scenario.review_date)}</Text>
              </View>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>IDENTIFIED DATE</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{formatDate(scenario.risk_identified_date)}</Text>
              </View>
              <View style={{ width: 110 }}>
                <Text style={pdfStyles.label}>UPDATED</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{formatDate(scenario.updated_at)}</Text>
              </View>
              <View>
                <Text style={pdfStyles.label}>EXPECTED LOSS</Text>
                <View style={pdfStyles.spacer1} />
                <Text style={pdfStyles.value}>{numeralFormat(scenario.expected_loss)}</Text>
              </View>
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <Text style={pdfStyles.gradText}>RISK STATEMENT</Text>
            <View style={pdfStyles.spacer8} />
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 220 }}>
                <Text style={pdfStyles.label}>SOURCES</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.source) ? (
                  <Text style={pdfStyles.value}>{riskSourcesNames[scenario.source]}</Text>
                ) : (
                  <NoData />
                )}
              </View>
              <View>
                <Text style={pdfStyles.label}>EVENTS</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.event) ? (
                  <Text style={pdfStyles.value}>{riskEventsNames[scenario.event]}</Text>
                ) : (
                  <NoData />
                )}
              </View>
            </View>
            <View style={pdfStyles.spacer8} />
            <View style={{ flexDirection: 'row' }}>
              <View style={{ width: 220 }}>
                <Text style={pdfStyles.label}>CONSEQUENCES</Text>
                <View style={pdfStyles.spacer1} />
                {!isUndefined(scenario.consequence) ? (
                  <Text style={pdfStyles.value}>{riskConsequencesNames[scenario.consequence]}</Text>
                ) : (
                  <NoData />
                )}
              </View>
              <View>
                <Text style={pdfStyles.label}>RISK OWNER</Text>
                <View style={pdfStyles.spacer1} />
                {scenario.risk_owner?.text ? (
                  <Text style={pdfStyles.value}>{scenario.risk_owner.text}</Text>
                ) : (
                  <NoData />
                )}
              </View>
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <Text style={pdfStyles.gradText}>LOSS EXCEEDANCE CURVE</Text>
            <View style={pdfStyles.spacer4} />
            {!isEmptyChart ? (
              <>
                <Image src={scenario.chartImage} style={pdfStyles.chartImage} />
                <View style={{ flexDirection: 'row', justifyContent: 'flex-end', gap: 12, marginTop: -8 }}>
                  <LegendExceedance />
                </View>
              </>
            ) : (
              <EmptyChart />
            )}
          </>

          <View style={pdfStyles.spacer8} />

          <>
            <View wrap={false}>
              <Text style={pdfStyles.label}>RISK NARRATIVE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.risk_narrative ? <Text style={pdfStyles.value}>{scenario.risk_narrative}</Text> : <NoData />}
            </View>
            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>FREQUENCY</Text>
              <View style={pdfStyles.spacer1} />
              {!(!scenario.frequency_times && !scenario.frequency_years) ? (
                <Text style={pdfStyles.value}>
                  {scenario.frequency_times ? scenario.frequency_times : '-'} time(s) per{' '}
                  {scenario.frequency_years ? scenario.frequency_years : '-'} year(s)
                </Text>
              ) : (
                <NoData />
              )}
            </View>
            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>NOTE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.frequency_note ? <Text style={pdfStyles.value}>{scenario.frequency_note}</Text> : <NoData />}
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <>
            <View wrap={false}>
              <Text style={pdfStyles.label}>CONSEQUENCE FACTORS</Text>
              <View style={pdfStyles.spacer8} />
              <View style={{ flexDirection: 'row' }}>
                <View style={{ width: 110, flexDirection: 'row' }}>
                  <Text style={{ ...pdfStyles.label, marginRight: 6 }}>LOWER:</Text>
                  <View style={pdfStyles.spacer1} />
                  {!isUndefined(scenario.lower) ? (
                    <Text style={pdfStyles.value}>{numeralFormat(scenario.lower)}</Text>
                  ) : (
                    <NoData />
                  )}
                </View>
                <View style={{ flexDirection: 'row' }}>
                  <Text style={{ ...pdfStyles.label, marginRight: 6 }}>UPPER:</Text>
                  <View style={pdfStyles.spacer1} />
                  {!isUndefined(scenario.upper) ? (
                    <Text style={pdfStyles.value}>{numeralFormat(scenario.upper)}</Text>
                  ) : (
                    <NoData />
                  )}
                </View>
              </View>
            </View>
            <View style={pdfStyles.spacer8} />
            <View wrap={false}>
              <Text style={pdfStyles.label}>NOTE</Text>
              <View style={pdfStyles.spacer1} />
              {scenario.note ? <Text style={pdfStyles.value}>{scenario.note}</Text> : <NoData />}
            </View>
          </>

          <View style={pdfStyles.spacer16} />
          <View style={pdfStyles.spacer8} />

          <View wrap={false}>
            <Text style={pdfStyles.gradText}>RISK MANAGEMENT</Text>
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.label}>MANAGEMENT STRATEGY</Text>
            <View style={pdfStyles.spacer1} />
            {scenario.management_strategy ? (
              <Text style={pdfStyles.value}>{scenario.management_strategy}</Text>
            ) : (
              <NoData />
            )}
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.label}>NOTE</Text>
            <View style={pdfStyles.spacer1} />
            {scenario.management_note ? <Text style={pdfStyles.value}>{scenario.management_note}</Text> : <NoData />}
          </View>

          <>
            <View style={pdfStyles.spacer16} />
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.gradText}>RISK MANAGEMENT PLAN FOR THIS SCENARIO</Text>
            <View style={pdfStyles.spacer8} />
            {scenario.controls.length ? <ControlsTable data={scenario.controls} /> : <NoData />}
          </>

          <>
            <View style={pdfStyles.spacer16} />
            <View style={pdfStyles.spacer8} />
            <Text style={pdfStyles.gradText}>INCIDENTS</Text>
            <View style={pdfStyles.spacer8} />
            {scenario.incidents.length ? <IncidentsTable data={scenario.incidents} /> : <NoData />}
          </>
        </View>
        <Footer footerComponent={<FooterStartComponent title={`${footerTitle}${scenario.name}`} />} />
      </Page>
    </>
  );
};
