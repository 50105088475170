import React, { forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { AuditItem, GetAuditV2Query, GetAuditV2RootPath, QKeys } from 'src/api/types';
import { Poppins, Spacer, TableSkeleton } from 'src/common';
import { useInfiniteQuery } from 'react-query';
import { DATE_FORMAT } from 'src/config';
import { isValid, format } from 'date-fns';
import { getAuditV2 } from 'src/api/other';
import _ from 'lodash';
import GenericTable from '../GenericTable';
import { ActionsGrid, Switch } from './comps';
import { composeSentence } from './comps/util';
import { mpEvent, MPEvents } from 'src/utils/mixpanel';
import { TableIds } from 'src/api/types/misc';
import { useAuditColumns } from './useAuditColumns';
import { AuditHandle } from './types';

interface AuditProps {
  rootPath: GetAuditV2RootPath;
  initQuery: GetAuditV2Query; //leave as required, otherwise falls into infinite loop
  noHide?: boolean;
  maxHeight?: number | string | null;
  customLabel?: {
    open: string;
    close: string;
  };
  tableId: TableIds;
}

const Audit = memo(
  forwardRef<AuditHandle, AuditProps>(
    ({ rootPath, initQuery = {}, noHide, maxHeight = 700, customLabel, tableId }, ref) => {
      const [showHistory, setShowHistory] = useState(!!noHide);
      const [desc, setDesc] = useState(true);
      const [sortBy, setSortBy] = useState('day');
      const [items, setItems] = useState<AuditItem[]>([]);
      const [skip, setSkip] = useState(0);

      useImperativeHandle(ref, () => ({
        refetch,
      }));

      const { canFetchMore, isFetching, refetch } = useInfiniteQuery(
        [QKeys.Audit, { rootPath, sortBy, desc, skip, ...initQuery }],
        () =>
          getAuditV2(rootPath, {
            skip,
            orderBy: sortBy,
            dir: desc ? 'desc' : 'asc',
            ...initQuery,
          }),
        {
          onSuccess: (data) => {
            if (data.length === 0 || skip === 0) {
              setItems(data.flatMap((el) => el.items));
            } else {
              setItems((prev) => [...prev, ...data.flatMap((el) => el.items)]);
            }
          },
          refetchOnMount: true,
          getFetchMore: (lastGroup, allGroups) => {
            const itemsFetched = allGroups.reduce((acc, group) => acc + group.items.length, 0);
            const moreItemsExist = itemsFetched < lastGroup.total;
            return moreItemsExist ? itemsFetched : false;
          },
        },
      );

      const itemsComposed = items.map((el) => {
        const date = new Date(el.date_time);
        return {
          ...el,
          date_time: isValid(date) ? format(date, `${DATE_FORMAT}, HH:mm`) : '',
          action_title: el.action_title || composeSentence(el.actions),
        };
      });

      const { columns, GTColumnSelectAnchorExported } = useAuditColumns({
        data: itemsComposed,
        tableId,
      });

      useEffect(() => {
        setItems([]);
        setSkip(0);
        refetch();
      }, [initQuery, sortBy, desc]);

      return (
        <>
          {!noHide && (
            <Switch
              title={customLabel ? (showHistory ? customLabel.open : customLabel.close) : ''}
              showHistory={showHistory}
              setShowHistory={(val) => {
                setShowHistory(val);
                mpEvent(MPEvents.AuditView, {
                  value: val ? 'open' : 'close',
                });
              }}
            />
          )}
          {showHistory && (
            <>
              {isFetching && !items.length ? (
                <>
                  <Spacer $px={30} />
                  <TableSkeleton />
                </>
              ) : (
                <>
                  {!items?.length ? (
                    <>
                      <Spacer $px={30} />
                      <Poppins px={14} color="cflowerBlue">
                        No activity recorded yet
                      </Poppins>
                    </>
                  ) : (
                    <>
                      <Spacer $px={30} />
                      <GenericTable
                        tableId={tableId}
                        data={itemsComposed}
                        columns={columns}
                        GTColumnSelectAnchorExported={GTColumnSelectAnchorExported}
                        expandContent={(data) => {
                          return data.actions?.length ? <ActionsGrid actions={data.actions || []} /> : undefined;
                        }}
                        controledSorting={{
                          state: { id: sortBy, desc },
                          onChange: ({ id, desc }) => {
                            setSortBy(id);
                            setDesc(desc);
                          },
                        }}
                        onBottomReached={() => {
                          if (canFetchMore && !isFetching) {
                            setSkip(items.length + 1);
                          }
                        }}
                        layout={{
                          maxHeight,
                          stickyHeader: true,
                        }}
                        usePagination={null}
                      />
                    </>
                  )}
                </>
              )}
            </>
          )}
        </>
      );
    },
  ),
  (prev, next) => _.isEqual(prev, next),
);

export default Audit;
